<template>
    <div>
        <div>
            <div class="mb-3">
                <div class="col-md-12">
                    <label class="form-col-label control-label required">{{
                        $t("leaveInitialBalance.employeeName")
                    }}</label>
                    <Input
                        v-model="model.employee_name"
                        type="text"
                        disabled
                    />
                </div>
            </div>

            <div class="col-md-12 tw-flex mb-3">
                <div class="col-md-6 tw-pr-2">
                    <label class="form-col-label control-label required">{{
                        $t("leaveInitialBalance.leaveType")
                    }}</label>
                    <Input
                        v-model="model.leave_type_name"
                        type="text"
                        disabled
                    />
                </div>
                <div class="col-md-6 tw-pl-2">
                    <label class="form-col-label control-label">{{
                        $t("leaveInitialBalance.adjustment")
                    }}</label>
                    <input
                        v-model.trim.number="model.adjustment"
                        type="number"
                        class="form-control"
                    />
                </div>
            </div>

            <div class="col-md-12 tw-flex mb-3">
                <div class="col-md-6 tw-pr-2">
                    <label class="form-col-label control-label required">{{
                        $t("leaveInitialBalance.carryForward")
                    }}</label>
                    <input
                        v-model.trim.number="model.carry_forward"
                        type="number"
                        class="form-control"
                        :class="{ 'is-invalid': errors.has('carry_forward') }"
                    />
                    <div
                        class="ivu-form-item-error tw-text-red-600"
                        v-if="errors.has('carry_forward')"
                    >
                        {{ errors.first("carry_forward") }}
                    </div>
                </div>
                <div class="col-md-6 tw-pl-2">
                    <label class="form-col-label control-label required">{{
                        $t("leaveInitialBalance.carryForwardClearDate")
                    }}</label>
                    <DatePicker
                        class="tw-w-full"
                        :value="model.carry_forward_clear_date"
                        @on-change="onChange"
                        format="dd-MM-yyyy"
                        type="date"
                        placeholder="DD-MM-YYYY"
                        :class="{
                            'ivu-form-item-error': errors.has(
                                'carry_forward_clear_date'
                            )
                        }"
                    />
                    <div
                        class="ivu-form-item-error tw-text-red-600"
                        v-if="errors.has('carry_forward_clear_date')"
                    >
                        {{ errors.first("carry_forward_clear_date") }}
                    </div>
                </div>
            </div>

            <div slot="footer">
                <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh">
                    <ts-button
                        @click.prevent="() => $emit('cancel')"
                        class="btn-gray"
                    >
                        {{ $t("cancel") }}</ts-button
                    >
                    <ts-button
                        v-if="isUpdate"
                        color="primary"
                        @click.prevent="onUpdate"
                        :waiting="waiting"
                    >
                        {{ $t("update") }}</ts-button
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapState } from "vuex";
import { isEmpty } from "lodash";

export default {
    name: "leave-initial-balance-form",
    data() {
        return {
            errors: new Errors(),
            loading: false,
            waiting: false,
            waiting_new: false,
            model: {
                employee_name: null,
                leave_type_name: null,
                adjustment: null,
                carry_forward: null,
                carry_forward_clear_date: null
            }
        };
    },
    computed: {
        ...mapState("humanResource/leaveInitialBalance", ["edit_data"]),
        isUpdate() {
            return !isEmpty(this.edit_data);
        }
    },
    methods: {
        onChange(data) {
            this.model.carry_forward_clear_date = data;
        },
        onUpdate() {
            this.waiting = true;
            this.$store
                .dispatch("humanResource/leaveInitialBalance/update", {
                    id: this.edit_data.leave_initial_balance_id,
                    data: this.model
                })
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.$emit("cancel");
                    this.$emit("fetchData");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        setEditData() {
            this.$nextTick(function() {});
            if (!isEmpty(this.edit_data)) {
                this.model.employee_name =
                    this.edit_data.employee.employee_name_en +
                    " | " +
                    this.edit_data.employee.employee_name_kh;
                this.model.leave_type_name =
                    this.edit_data.leave_type.leave_type_en +
                    " | " +
                    this.edit_data.leave_type.leave_type_kh;
                this.model.adjustment = this.edit_data.adjustment;
                this.model.carry_forward = this.edit_data.carry_forward;
                this.model.carry_forward_clear_date = this.edit_data.carry_forward_clear_date;
            }
        },
        clearInput() {
            this.errors = new Errors();
            this.model.employee_name = null;
            this.model.leave_type_name = null;
            this.model.adjustment = null;
            this.model.carry_forward = null;
            this.model.carry_forward_clear_date = null;
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "LEAVE INITIAL BALANCE",
                desc: not.text
            });
        }
    }
};
</script>
