<template>
    <div>
        <ts-page-title
            :title="$t('leaveInitialBalance.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('leaveInitialBalance.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <div class="tw-flex tw-justify-between">
                    <div class="tw-flex">
                        <DatePicker
                            :value="cycle_year"
                            @on-change="onChange"
                            format="yyyy"
                            type="year"
                            style="width: 200px;"
                            class="tw-px-2"
                        />
                        <ts-button
                            color="primary"
                            :waiting="generating"
                            class="tw-px-2"
                            style="width: 200px;"
                            @click="generate()"
                        >
                            {{ $t("leaveInitialBalance.generate") }}</ts-button
                        >
                    </div>
                    <div class="tw-justify-end tw-space-x-2">
                        <Input
                            search
                            v-model="search"
                            :placeholder="$t('leaveInitialBalance.search')"
                            :style="width"
                            @on-focus="() => (width = 'width: 500px')"
                            @on-blur="() => (width = 'width: 300px')"
                        />
                    </div>
                </div>
            </ts-panel-wrapper>
            <div class="overflow-auto">
                <ts-table
                    :loading="this.loading"
                    class="tw-whitespace-nowrap "
                    :records="resources"
                    :columns="columns"
                    :rowGroup="{
                        field: 'employee.employee_name_en'
                    }"
                >
                    <template v-slot="{ record }">
                        <td width="150px">
                            <span>
                                {{ record.leave_type.leave_type_en }} |
                                {{ record.leave_type.leave_type_kh }}
                            </span>
                        </td>
                        <td width="150px" class="tw-text-center">
                            <span>
                                {{ record.cycle_start }}
                            </span>
                        </td>
                        <td width="150px" class="tw-text-center">
                            <span>
                                {{ record.cycle_stop }}
                            </span>
                        </td>
                        <td width="250px" class="tw-text-center">
                            <span>
                                {{ record.carry_forward_clear_date }}
                            </span>
                        </td>
                        <td width="250px" class="tw-text-center">
                            <span>
                                {{ record.entitle_balance }}
                            </span>
                        </td>
                        <td width="150px" class="tw-text-center">
                            <span>
                                {{ record.increment_day }}
                            </span>
                        </td>
                        <td width="150px" class="tw-text-center">
                            <span>
                                {{ record.adjustment }}
                            </span>
                        </td>
                        <td width="150px">
                            <span>
                                {{ record.carry_forward }}
                            </span>
                        </td>
                        <td width="150px" class="tw-text-center">
                            <span>
                                {{ record.carry_forward_used }}
                            </span>
                        </td>
                        <td width="150px">
                            <span>
                                {{ record.carry_forward_cleared }}
                            </span>
                        </td>
                        <td
                            class="tw-align-middle tw-text-center"
                            width="150px"
                        >
                            <a
                                href="#"
                                class="text-primary ml-2"
                                @click.prevent="onEdit(record)"
                                v-tooltip="$t('edit')"
                            >
                                <Icon type="ios-create" size="20" />
                            </a>
                        </td>
                    </template>
                </ts-table>
            </div>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-i-pagination>
            </div>

            <Modal
                v-model="showForm"
                draggable
                sticky
                scrollable
                :mask="false"
                :footer-hide="true"
                :z-index="1022"
                :title="$t('leaveInitialBalance.pageTitle')"
            >
                <from-action
                    ref="form_action"
                    @cancel="clearEdit"
                    @fetchData="fetchData"
                />
            </Modal>
        </ts-panel>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import moment from "moment";
import { debounce } from "lodash";
import { mapState } from "vuex";
import FromAction from "./form";

export default {
    name: "leave-initial-balance",
    components: {
        FromAction
    },
    data() {
        return {
            cycle_year: moment()
                .year()
                .toString(),
            loading: false,
            showForm: false,
            generating: false,
            width: "width: 300px"
        };
    },
    computed: {
        ...mapState("humanResource/leaveInitialBalance", [
            "resources",
            "pagination"
        ]),
        search: {
            get() {
                return this.$store.state.humanResource.leaveInitialBalance
                    .search;
            },
            set(newValue) {
                this.$store.commit(
                    "humanResource/leaveInitialBalance/SET_SEARCH",
                    newValue
                );
                this.$store.commit(
                    "humanResource/leaveInitialBalance/RESET_CURRENT_PAGE"
                );
            }
        },
        columns() {
            return [
                {
                    name: this.$t("leaveInitialBalance.leaveType")
                },
                {
                    name: this.$t("leaveInitialBalance.cycleStart"),
                    style: "text-align:center"
                },
                {
                    name: this.$t("leaveInitialBalance.cycleStop"),
                    style: "text-align:center"
                },
                {
                    name: this.$t("leaveInitialBalance.carryForwardClearDate"),
                    style: "text-align:center"
                },
                {
                    name: this.$t("leaveInitialBalance.entitleBalance"),
                    style: "text-align:center"
                },
                {
                    name: this.$t("leaveInitialBalance.incrementDay"),
                    style: "text-align:center"
                },
                {
                    name: this.$t("leaveInitialBalance.adjustment"),
                    style: "text-align:center"
                },
                {
                    name: this.$t("leaveInitialBalance.carryForward")
                },
                {
                    name: this.$t("leaveInitialBalance.carryForwardUsed"),
                    style: "text-align:center"
                },
                {
                    name: this.$t("leaveInitialBalance.carryForwardCleared")
                },
                {
                    name: this.$t("actions"),
                    style: "text-align:center"
                }
            ];
        }
    },
    methods: {
        onChange(data) {
            this.cycle_year = data;
        },
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("humanResource/leaveInitialBalance/fetch", {
                    cycle_year: this.cycle_year,
                    ...attributes
                })
                .then(() => {
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.notice({ type: "error", text: error.message });
                });
        },
        generate() {
            this.generating = true;
            this.$store
                .dispatch("humanResource/leaveInitialBalance/generate", {
                    cycle_year: this.cycle_year
                })
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.fetchData();
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.generating = false;
                });
        },
        onEdit(record) {
            this.$store.commit(
                "humanResource/leaveInitialBalance/SET_EDIT_DATA",
                record
            );
            this.showForm = true;
            this.$refs.form_action.setEditData();
        },
        clearEdit() {
            this.showForm = false;
            this.$refs.form_action.setEditData();
            this.$refs.form_action.clearInput();
            this.$store.commit(
                "humanResource/leaveInitialBalance/SET_EDIT_DATA",
                {}
            );
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "LEAVE INITIAL BALANCE",
                desc: not.text
            });
        }
    },
    watch: {
        search: debounce(function(search) {
            this.fetchData({ search, cycle_year: this.cycle_year });
        }, 500),
        cycle_year: function(cycleYear) {
            this.fetchData({ cycle_year: cycleYear });
        }
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("humanResource/leaveInitialBalance/RESET_STATE");
        next();
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchData();
        });
    }
};
</script>
